AdsenseAd {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  ins {
    justify-content: center;
    align-items: center;
  }
}